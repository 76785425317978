<template>
    <section class="pqr-ver-resolucion mt-4 ml-4">
        <div class="row mb-4">
            <div class="col-12 col-lg-4">
                 <div class="row mt-4">
                    <div class="col-12">
                        <el-breadcrumb separator-class="el-icon-arrow-right" class="f-300">
                            <el-breadcrumb-item :to="{ name: 'pqr.resoluciones' }">Resoluciones</el-breadcrumb-item>
                            <el-breadcrumb-item>{{ $route.params.id_grupo ? 'Resolución' : 'Nueva resolución'}}</el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-12">
                        <div class="row">
                            <p class="col-auto f-20 f-500 mb-3">Resolución</p>
                            <div class="col-auto ml-auto">
                                 <el-tooltip v-if="$route.params.id_grupo" placement="bottom-end" effect="light" :visible-arrow="false" popper-class="border-0 shadow-sm">
                                    <div slot="content" class="text-86">
                                        <p class="cr-pointer" @click="modalEliminarResolucion">Eliminar</p>
                                    </div>
                                    <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer ml-2">
                                        <i class="icon-vertical-points" />
                                    </div>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <ValidationObserver ref="formPrincipal" class="w-100">
                        <ValidationProvider tag="div" class="col-12 col-sm-8 mb-3" v-slot="{errors}" rules="required|max:60" name="Nombre">
                            <p class="f-12 pl-2 label-inputs">Título</p>
                            <el-input placeholder="Nombre" v-model="resolucion.titulo" maxlength="60" show-word-limit size="small" class="w-100" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                        <ValidationProvider tag="div" class="col-12 mb-3">
                            <div class="d-middle">
                                <p class="f-15">Activar</p>
                                <el-switch v-model="resolucion.estado" :active-value="1" :inactive-value="0" active-color="#00D9B5" inactive-color="#E6E6E6" class="mx-2" />
                            </div>
                        </ValidationProvider>
                    <div v-if="$route.params.id_grupo" class="d-flex">
                        <div class="col-auto">
                            <span class="bg-verde-claro rounded-pill f-12 px-2 py-1">
                                {{ resolucion.pqrs_activas_count }} PQRs Vigentes
                            </span>
                        </div>
                        <div class="col-auto">
                            <span class="bg-amarillo1 rounded-pill f-12 px-2 py-1">
                                {{ resolucion.pqrs_count }} PQRs Totales
                            </span>
                        </div>
                    </div>
                    <div class="col-12 mt-4">
                        <button class="btn btn-general h-32px f-14 f-300 px-4" @click="guardarResolucion">Guardar</button>
                    </div>
                    </ValidationObserver>
                </div>
            </div>
            <div v-if="$route.params.id_grupo" class="col col-lg-4 overflow-auto custom-scroll" style="height:calc(100vh - 110px);">
                <div class="row mx-0">
                    <div class="col-auto">
                        <p class="f-20 f-500">Comentarios</p>
                    </div>
                    <div class="col-auto ml-auto">
                       <div class="d-middle">
                            <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" content="Añadir imagen" popper-class="rounded-pill py-2 border-0 shadow-sm">
                                <el-upload
                                    action="#"
                                    :auto-upload="false"
                                    :on-change="subirImagenGrupo"
                                    :show-file-list="false"
                                    :file-list="fileList">
                                    <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer">
                                        <i class="icon-add-img" />
                                    </div>
                                </el-upload>
                            </el-tooltip>
                            <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="rounded-pill py-2 border-0 shadow-sm">
                                <div slot="content" class="text-86">
                                    Añadir comentario
                                </div>
                                <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer ml-2" @click="agregarComentario(null)">
                                    <i class="icon-message-reply-outline" />
                                </div>
                            </el-tooltip>
                       </div>
                    </div>
                </div>
                <div v-for="(com,index) in comentarios" :key="index" class="mx-0 my-3" >
                    <div class="col-12">
                        <div v-if="com.tipo==1" class="set-comentarios br-4 p-2 mb-3 cr-pointer" @click="agregarComentario(index)">
                            <p  class="f-15 lh-18" v-html="formatearTextoHtml(com.comentario)"></p>
                        </div>
                            <div v-else  class="contenedor-img" style="height:100px;width:100px;">
                                <img :src="com.comentario" width="100px" height="100px" class="br-4" @click="handlePictureCardPreview(com.comentario)">
                                <div class="opciones text-general">
                                    <i class="icon-search mr-2" @click="handlePictureCardPreview(com.comentario)" />
                                    <i class="icon-delete-outline" @click="modalEliminarImagen(index)" />
                                </div>
                            
                        </div>
                    </div>
                </div>
                <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
            </div>
            <div v-if="$route.params.id_grupo" class="col-12">
                <infinite-loading @infinite="getComentariosGrupo">
                    <div slot="spinner">Consultando...</div>
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                </infinite-loading>
            </div>
        </div>
        <!-- Partials -->
        <modal-ver-img ref="abrirModalVerImagenes" />
        <modal-lateral ref="abrirModalAgregarComentario" :titulo="`${comentarioSelected != null ? 'Ver' : 'Crear'} comentario`">
            <ValidationObserver tag="div" class="row mx-0" ref="formComentario">
                    <div class="col-12">
                        <ValidationProvider tag="div" v-slot="{errors}" rules="required|max:1200" name="comentario">
                            <el-input placeholder="Comentario" v-model="inputComentario" :disabled="comentarioSelected != null" type="textarea" show-word-limit maxlength="1200"  :autosize="{ minRows: 5}" />
                            <p class="text-danger f-10">{{ errors[0] }}</p>
                        </ValidationProvider>
                        <el-tooltip v-if="comentarioSelected != null" placement="bottom-end" effect="light" :visible-arrow="false" popper-class="rounded-pill py-2 border-0 shadow-sm">
                            <div slot="content" class="text-86">
                                Eliminar comentario
                            </div>
                            <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer ml-auto mt-2" @click="modalEliminarComentario">
                                <i class="icon-delete-outline" />
                            </div>
                        </el-tooltip>
                    </div>
                    <div v-if="comentarioSelected == null" class="botones">
                         <button type="button" class="btn mx-2 btn-cerrar f-300 f-14 h-32px px-4 br-4 border bg-db text-86" @click="cerrarComentario"> Cerrar </button>
                         <button type="button" class="btn mx-2 btn-aceptar f-14 h-32px text-white br-4 bg-db" @click="crearComentarioGrupo"> Enviar </button>
                    </div>
            </ValidationObserver>
        </modal-lateral>
        <modal-eliminar ref="modalEliminarComentario" titulo="Eliminar comentario" mensaje="¿Desea eliminar este comentario?" tamano="modal-md" class="f-15 text-center" @eliminar="eliminarComentario"/>
        <modal-eliminar ref="modalEliminarImagen" titulo="Eliminar imagen" mensaje="¿Desea eliminar esta imagen?" tamano="modal-md" class="f-15 text-center" @eliminar="eliminarImagen"/>
        <modal-eliminar ref="modalEliminarResolucion" titulo="Eliminar resolución" mensaje="¿Desea eliminar esta resolución?" tamano="modal-md" class="f-15 text-center" @eliminar="eliminarResolucion"/>
    </section>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading';

import PQRS from '~/services/pqrs'
export default {
    components:{
        modalVerImg: () => import ('./partials/modalVerImg'),
        InfiniteLoading
    },
    data(){
        return{
            fileList: [],
            page: 1,
            resolucion: {
                id: null,
                titulo: null,
                estado: null,
            },
            comentarioSelected: null,
            dialogVisible: false,
            dialogImageUrl: '',
            comentarios: [],
            inputSearch:'',
            inputNombreResolucion:'Nombre de resolución',
            inputComentario:'',
            activarResolucion:true,
        }
    },
    created(){
        if(this.$route.params.id_grupo) this.getResolucion()
    },
    methods:{
        agregarComentario(indexComentario = null){
            if (indexComentario != null) {
                this.comentarioSelected = indexComentario
                this.inputComentario = this.comentarios[indexComentario].comentario
                this.$refs.abrirModalAgregarComentario.toggle()  
            } else {
                this.comentarioSelected = null
                this.inputComentario = ''
                this.$refs.abrirModalAgregarComentario.toggle()  
            }
            
        },
        cerrarComentario(){
            this.$refs.abrirModalAgregarComentario.toggle()
        },
        modalEliminarComentario(){
            this.cerrarComentario()
            this.$refs.modalEliminarComentario.toggle()
        },
        modalEliminarImagen(indexImagen){
            this.comentarioSelected = indexImagen
            this.$refs.modalEliminarImagen.toggle()
        },
        modalEliminarResolucion(){
            this.$refs.modalEliminarResolucion.toggle()
        },
        async eliminarResolucion(){
            try {
                let params = {
                    id_grupo: this.resolucion.id
                }
                const { data } = await PQRS.eliminarResolucion(params)

                this.notificacion('','Resolución eliminada correctamente','success')
                this.$refs.modalEliminarResolucion.toggle()
                this.$router.replace({name: "pqr.resoluciones"})
            } catch (error) {
                this.errorCatch(error)
            }
        },
        async guardarResolucion(){
            try {
                let validate = await this.$refs.formPrincipal.validate()
                if(!validate) return this.notificacion('','Por favor complete los campos') 

                let params = {
                    id_grupo: this.resolucion.id,
                    titulo: this.resolucion.titulo,
                    estado: this.resolucion.estado,
                }

                const { data } = await PQRS.crearResolucion(params)

                this.notificacion('','Resolución guardada correctamente','success')
                this.$router.push({name: "pqr.resoluciones"})

            } catch (error) {
                this.errorCatch(error)
            }
        },
        async getResolucion(){
            try {
                const { data } = await PQRS.getResolucionXId(this.$route.params.id_grupo)
                this.resolucion = data.data

            } catch (error) {
                this.errorCatch(error)
                this.$router.replace({name: "pqr.resoluciones"})
            }
        },
        async subirImagenGrupo(file,fileList){
            try {
                let params = {
                    id_grupo: this.resolucion.id,
                    comentario: file.raw
                }

                const { data } = await PQRS.crearComentarioPqr(this.crearFormData(params))

                this.comentarios = []
                this.page = 1    
                this.getComentariosGrupo()

            } catch (error) {
                this.errorCatch(error)
            }
        },
        async getComentariosGrupo($state){
            try {
                let page = this.page
                const state = $state
                const { data } = await PQRS.getComentariosGrupo(this.$route.params.id_grupo,{page})

                if(data.data.data.length){
                this.page += 1;
                this.comentarios.push(...data.data.data)
                if(typeof state !== 'undefined'){
                    state.loaded();
                }
                }else{
                if(typeof state !== 'undefined'){
                    state.complete();
                }
                }
            } catch (error) {
                this.errorCatch(error)
            }
        },
        async crearComentarioGrupo(){
            try {

                let validate = await this.$refs.formComentario.validate()
                if(!validate) return this.notificacion('','Por favor escribe un comentario valido')

                let params = {
                    id_grupo: this.resolucion.id,
                    comentario: this.inputComentario
                }

                const { data } = await PQRS.crearComentarioPqr(params)
                
                this.comentarios = []
                this.page = 1
                this.getComentariosGrupo()
                this.cerrarComentario()
            } catch (error) {
                this.errorCatch(error)
            }
        },
        async eliminarComentario(){
            try {
                let id_grupo_comentario = this.comentarios[this.comentarioSelected].id
                const { data } = await  PQRS.eliminarComentarioGrupo({id_grupo_comentario})

                this.notificacion('','Comentario eliminado correctamente','success')
                this.comentarios = []
                this.page = 1
                this.getComentariosGrupo()
                this.$refs.modalEliminarComentario.toggle()
            } catch (error) {
                this.errorCatch(error)
            }
        },
        async eliminarImagen(){
            try {
                let id_grupo_comentario = this.comentarios[this.comentarioSelected].id
                const { data } = await PQRS.eliminarImagenGrupo({id_grupo_comentario})

                this.notificacion('','Imagen eliminada correctamente','success')
                this.comentarios = []
                this.page = 1
                this.getComentariosGrupo()
                this.$refs.modalEliminarImagen.toggle()
                
            } catch (error) {
                this.errorCatch(error)
            }
        },
        handlePictureCardPreview(file) {
        this.dialogImageUrl = file;
        this.dialogVisible = true;
        },

    }
}
</script>
<style lang="scss" scoped>
.pqr-ver-resolucion{
    .set-comentarios{
        background: var(--color-f5);
        border: 1px solid var(--color-db);
    }
    .contenedor-img{
        width: 112px;
        height: 74px;
        position: relative;
        .opciones{
            position: absolute;
            bottom: 7px;
            left: 36px;
            transition: .35s;
            cursor: pointer;
            opacity: 0;
        }
        &:hover{
            .opciones, .icon-me_interesa{
                transition: .45s;
                opacity: 1;
            }
        }
    }
    .bg-amarillo1{
        background: #FFD54F;
    }
}
</style>